import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import Breadcrumbs from '../components/Breadcrumbs'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share'
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather'

import ContactForm from '../components/contact-form'
import { BlogPostsOrRelated } from '../components/BlogPostsOrRelated'

class BlogTemplate extends Component {
  render() {
    const post = this.props.data.contentfulBlogPost
    const siteurl = this.props.data.site.siteMetadata.siteUrl
    const category = post.category && post.category[0]
    const relatedPosts = this.props.data.related

    console.log('relatedPosts', relatedPosts)
    return (
      <Layout
        pageProps={this.props}
        backgroundGrey={true}
        location={this.props.location}
      >
        <SEO
          title={post.metaTitle ? post.metaTitle : post.title}
          description={post.metaDescription ? post.metaDescription : post.title}
          img={post.heroImage.fixed.src}
          twitterCardContent={'summary_large_image'}
        />{' '}
        <div className="bg-white border-b border-gray-100">
          <nav
            className="flex container mx-auto px-4 py-3"
            aria-label="Breadcrumb"
          >
            <Breadcrumbs
              links={[
                { to: '/', label: 'Home' },
                { to: '/blog/', label: 'Blog' },
                { to: `/blog/${post.slug}`, label: post.title },
              ]}
            />
          </nav>
        </div>
        <section className="section px-4 md:px-6 bg-gray-50">
          <article itemScope itemType="https://schema.org/Article">
            <div
              itemProp="publisher"
              itemScope
              itemType="https://schema.org/Organization"
              className="hidden"
            >
              <span itemProp="name" className="hidden">
                WebPerfex
              </span>
            </div>
            <div>
              <div className="flex flex-wrap container mx-auto py-10">
                <div className="w-full md:w-2/3 p-0 md:pr-16">
                  <Link
                    className="font-semibold block text-base text-blue-500 tracking-wide uppercase"
                    title={category.name}
                  >
                    {category.name}
                  </Link>

                  {category.slug}
                  <h1
                    itemProp="name headline"
                    className="font-display my-2 text-3xl  md:text-4xl font-bold"
                  >
                    {post.title}
                  </h1>

                  <p className="font-medium text-base text-gray-600">
                    By <span itemProp="author">{post.author.name}</span>{' '}
                    <time
                      itemProp="datePublished"
                      className="text-gray-600"
                      content={post.microDataPublishDate}
                    >
                      — {post.publishDate}
                    </time>
                  </p>

                  <br />

                  <div className="mx-auto overflow-hidden  relative z-10 -mx-4 md:rounded md:shadow-lg md:mx-0">
                    <div
                      itemProp="image"
                      itemScope
                      itemType="http://schema.org/ImageObject"
                    >
                      <img
                        src={post.heroImage.fixed.src}
                        className="hidden"
                        width="900"
                        height="400"
                        itemProp="url"
                        alt={post.title}
                      />

                      <Img
                        fluid={post.heroImage.fluid}
                        alt={post.title}
                        style={{
                          height: '300px',
                          objectFit: 'cover',
                          boxShadow: '1px 2px 8px 1px hsla(0,0%,0%,0)',
                        }}
                        url={post.heroImage.fixed.src}
                        className="blogPostImage"
                      />
                    </div>
                  </div>
                  <div className="mb-4 md:mt-4 flex bg-white -mx-4 md:mx-0 px-6 justify-content-end p-2 social-icons items-center shadow-md">
                    <p className="text-base font-display font-bold">
                      Share This Post —{' '}
                    </p>
                    <FacebookShareButton
                      url={siteurl + '/blog/' + post.slug}
                      quote={post.title}
                      hashtag={'#webperfex'}
                      className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                    >
                      <Facebook size={20} />
                    </FacebookShareButton>

                    <TwitterShareButton
                      url={siteurl + '/blog/' + post.slug}
                      title={post.title}
                      hashtags={post.tags}
                      className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                    >
                      <Twitter size={20} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={siteurl + '/blog/' + post.slug}
                      title={post.title}
                      quote={post.title}
                      hashtags={post.tags}
                      className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                    >
                      <Linkedin size={20} />
                    </LinkedinShareButton>
                    <EmailShareButton
                      subject={post.title}
                      url={siteurl + '/blog/' + post.slug}
                      openWindow={true}
                      body={'Check out this article: '}
                      className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                    >
                      <Mail size={20} className="stroke-white" />
                    </EmailShareButton>
                  </div>

                  <div className="articleBody prose markdown-content mt-8">
                    
                    <div
                      itemProp="articleBody"
                      dangerouslySetInnerHTML={{ __html: post.body.body }}
                    />
                  </div>
                </div>

                <div className="w-full md:w-1/3 p-0 md:pl-6">
                  <div className="sticky top-0  z-1 ">
                    <div className="h-2"></div>
                    <div className="bg-white shadow-xl rounded-xl p-6 rounded-x mt-2">
                      <h3 class="text-left font-bold font-display text-xl md:text-2xl">
                        Start with a FREE Quote!
                      </h3>
                      <h3 class="font-normal text-gray-600 text-left mb-5 text-md">
                        No Obligation Estimate, Expert Advice
                      </h3>

                      <div>
                        <ContactForm
                          showLabels
                          showPlaceHolders
                          pageLocation={
                            this.props.location.href
                              ? this.props.location.href
                              : 'Not provided'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
        <BlogPostsOrRelated
          title={`More in ${post.category.map(({ name }) => name).join(',')}`}
          data={relatedPosts.edges || []}
        />
      </Layout>
    )
  }
}

export default BlogTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $categoryId: [String]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      slug
      metaTitle
      metaDescription
      publishDate(formatString: "MMMM Do, YYYY")
      microDataPublishDate: publishDate(formatString: "YYYY-MM-D")
      heroImage {
        fluid(maxWidth: 1500, quality: 70) {
          ...GatsbyContentfulFluid_withWebp
        }
        fixed(width: 1200, height: 630) {
          width
          height
          src
          srcSet
          srcWebp
        }
      }
      author {
        name
        slug
      }
      body {
        body
      }
      category {
        name
      }
      tags
    }
    related: allContentfulBlogPost(
      filter: {
        category: { elemMatch: { id: { in: $categoryId } } }
        slug: { ne: $slug }
      }
      sort: { fields: publishDate, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          id
          title
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          heroImage {
            fluid(maxWidth: 400, quality: 70) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          metaDescription
          author {
            name
          }
          category {
            name
          }
        }
      }
    }
  }
`
